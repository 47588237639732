<script setup>
  import { onMounted, watch, ref, nextTick } from 'vue';

  const props = defineProps({
    initialAnimation: Boolean,
  });

  const timeoutId = ref();
  const svgEl = ref();

  watch(
    () => [props.initialAnimation, svgEl],
    async ([anim]) => {
      clearTimeout(timeoutId.value);
      await nextTick();
      if (anim) {
        svgEl.value.classList.add('bb-plus-user-icon-animation');
      }

      timeoutId.value = setTimeout(() => {
        svgEl.value.classList.remove('bb-plus-user-icon-animation');
      }, 6100);
    },
    { immediate: true },
  );
</script>

<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    class="user-icon-svg"
    ref="svgEl"
  >
    <rect width="24" height="24" fill="white" />
    <path
      class="user-icon-fill"
      d="M6 10.25C6 13.4256 8.57436 16 11.75 16C14.9256 16 17.5 13.4256 17.5 10.25H16C16 12.5972 14.0972 14.5 11.75 14.5C9.40279 14.5 7.5 12.5972 7.5 10.25H6Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      class="user-icon-fill"
      d="M11.75 17.5C9.42278 17.5 7.28029 18.0881 5.69565 19.0785C4.12157 20.0623 3 21.5174 3 23.25C3 23.6642 3.33579 24 3.75 24H19.75C20.1642 24 20.5 23.6642 20.5 23.25C20.5 21.5174 19.3784 20.0623 17.8044 19.0785C16.2197 18.0881 14.0772 17.5 11.75 17.5ZM17.0094 20.3505C18.0094 20.9755 18.6359 21.726 18.8815 22.5H4.61848C4.86406 21.726 5.4906 20.9755 6.49064 20.3505C7.80143 19.5312 9.65894 19 11.75 19C13.8411 19 15.6986 19.5312 17.0094 20.3505Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      class="user-icon-fill"
      d="M12.6499 0.502397L14.5202 2.89884L16.5589 1.41618C16.787 1.25026 17.089 1.22638 17.3403 1.35439C17.5917 1.4824 17.75 1.74063 17.75 2.02273V7.75C17.75 8.16421 17.4142 8.5 17 8.5H6.5C6.08579 8.5 5.75 8.16421 5.75 7.75V2.02273C5.75 1.74063 5.90829 1.4824 6.15967 1.35439C6.41104 1.22638 6.71299 1.25026 6.94113 1.41618L8.97975 2.89881L10.8497 0.502426C11.2526 -0.167463 12.247 -0.167478 12.6499 0.502397ZM11.7498 1.78714L9.71629 4.39321C9.46754 4.71198 9.01088 4.77619 8.68387 4.53837L7.25 3.49555V7H16.25V3.49555L14.8161 4.53837C14.4891 4.77618 14.0325 4.71199 13.7837 4.39325L11.7498 1.78714Z"
    />
  </svg>
</template>

<style lang="scss" scoped>
  .bb-plus-user-icon-animation {
    animation-name: bb-plus-user-icon-anim;
  }

  .user-icon-svg {
    animation-duration: 4s;
    animation-delay: 2s;
    animation-direction: alternate;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  @keyframes bb-plus-user-icon-anim {
    // Show tag
    0% {
      fill: #222222;
    }
    10% {
      fill: #5858da;
    }

    // keep tag

    // hide tag
    90% {
      fill: #5858da;
    }
    100% {
      fill: #222222;
    }
  }
</style>
