<template>
  <div
    class="navbar-user bb-row bb-align-items-center bb-justify-content-around"
  >
    <div class="bb-plus-tag-wrapper" ref="bbPlusWrapper">
      <div class="bb-plus-tag-container">
        <i class="bb-plus-tag-detail" />
        <span>Sos Bigbox+</span>
      </div>
    </div>
    <router-link v-if="!user" :to="{ name: 'login' }">
      <NormalUserIcon ref="userIcon" class="user-icon" />
    </router-link>
    <div
      v-else
      class="user"
      ref="userIcon"
      :style="{
        color: isSpain && $route.name === 'home' ? '#FFFFFF' : '#464646',
      }"
    >
      <NotificationCircle
        v-if="user.has_unused_gifts"
        class="notification-position"
      />
      <NormalUserIcon
        v-if="!isBigboxPlus"
        @click.native="toggleMenu()"
        ref="userIcon"
        class="user-icon"
      />
      <PlusUserIcon
        v-else
        @click.native="toggleMenu()"
        ref="userIcon"
        :initialAnimation="isBigboxPlus"
        class="user-icon"
      />
      <transition name="menu">
        <div v-if="showMenu" v-click-outside="toggleMenu">
          <mobile-header-user-menu
            v-if="isTablet || isMobile"
            :user="user"
            class="mobile-user-menu"
            @refetchUser="refetchUser()"
            @close="toggleMenu"
          />
          <header-user-menu
            v-else
            :user="user"
            class="desktop-user-menu"
            @refetchUser="refetchUser()"
            @close="toggleMenu()"
          />
        </div>
      </transition>
    </div>
    <credit-advice
      v-if="creditAdviceFlag && userCreditAmount"
      :user="user"
      :userCreditAmount="userCreditAmount"
      @closedCreditAdvice="closeCreditAdvice()"
      v-click-outside="closeCreditAdvice"
    >
    </credit-advice>
  </div>
</template>
<script>
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import outSideClick from 'directives/outSideClick';
  import CreditAdvice from 'components/CreditAdvice/CreditAdvice.vue';
  import useIsMobile from 'common/composables/useIsMobile.js';
  import BbModal from 'common/components/BbModal';
  import HeaderUserMenu from 'components/Header/HeaderUserMenu/HeaderUserMenu.vue';
  import MobileHeaderUserMenu from 'components/Header/MobileHeaderUserMenu/MobileHeaderUserMenu.vue';
  import NotificationCircle from '../NotificationCircle/NotificationCircle.vue';
  import NormalUserIcon from './NormalUserIcon.vue';
  import PlusUserIcon from './PlusUserIcon.vue';

  Vue.directive('outSideClick', outSideClick);

  @Component({
    components: {
      HeaderUserMenu,
      CreditAdvice,
      BbModal,
      MobileHeaderUserMenu,
      NotificationCircle,
      NormalUserIcon,
      PlusUserIcon,
    },
    props: {
      user: {
        type: Object,
      },
      isSpain: {
        type: Boolean,
        default: false,
      },
    },
    directives: {
      clickOutside: outSideClick.directive,
    },
    setup() {
      const { isTablet, isMobile } = useIsMobile();

      return {
        isTablet,
        isMobile,
      };
    },
    watch: {
      user(user) {
        if (!user) return;

        const isBigboxPlus = !!user?.is_bigbox_plus;
        const elBigboxPlusWrapper = this.$refs.bbPlusWrapper;

        // Run Bigbox plus animation
        if (isBigboxPlus) {
          elBigboxPlusWrapper.classList.add('bb-plus-tag-wrapper-animation');
          setTimeout(() => {
            elBigboxPlusWrapper.classList.remove(
              'bb-plus-tag-wrapper-animation',
            );
          }, 6100);
        }
      },
    },
  })
  export default class NavbarUserMenuComponent extends Vue {
    showMenu = false;
    userCreditAmount = Math.trunc(parseInt(this.user?.credit_amount));
    creditAdviceFlag =
      window.localStorage.getItem('creditAdviceFlag') === 'true';

    get isBigboxPlus() {
      return !!this.user?.is_bigbox_plus;
    }

    closeCreditAdvice() {
      this.creditAdviceFlag = false;
      window.localStorage.setItem('creditAdviceFlag', 'false');
    }

    onClickOutside() {
      this.closeCreditAdvice();
    }

    refetchUser() {
      this.$emit('refetchUser');
    }

    toggleMenu() {
      this.showMenu = !this.showMenu;
    }
  }
</script>

<style lang="scss" scoped>
  @import '~assets/styles/index.scss';

  .navbar-user {
    position: relative;
    z-index: 30;

    .user-icon {
      display: block;
      height: 24px;
      width: 24px;
      position: relative;
      top: -3px;
    }

    .user {
      height: 24px;
      width: 24px;
      position: relative;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;

      .notification-position {
        position: absolute;
        top: -1px;
        right: 0;
      }

      .profile-img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: no-repeat center;
        background-size: cover;
      }

      .desktop-user-menu {
        position: absolute;
        top: 44px;
        right: -48px;
        z-index: $HEADER-Z-INDEX + 2;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
      }

      .mobile-user-menu {
        z-index: $HEADER-Z-INDEX + 3;
      }
    }
    .menu-enter-active {
      @media (min-width: $screen-md) {
        transition: opacity 0.4s;
      }
    }

    .menu-leave-active {
      @media (min-width: $screen-md) {
        transition: opacity 0s;
      }
    }

    .menu-enter {
      @media (min-width: $screen-md) {
        opacity: 0;
      }
    }
  }

  .bb-plus-tag-wrapper-animation {
    animation-name: bb-plus-tag-anim;
  }

  .bb-plus-tag-wrapper {
    position: absolute;
    z-index: 30;
    animation-duration: 4s;
    animation-delay: 2s;
    animation-direction: alternate;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    top: 50%;
    transform: translateY(-50%);
    right: calc(100% + 4px);
    width: 0;
    opacity: 0;

    .bb-plus-tag-detail {
      position: absolute;
      // border: 1px solid red;
      background: #5858da;
      top: 50%;
      transform: translateY(-50%) rotateZ(45deg);
      left: calc(100% - 4px);
      width: 6px;
      height: 6px;
    }

    .bb-plus-tag-container {
      padding: 4px 6px;
      background: #5858da;
      border-radius: 100px;
      overflow: hidden;

      span {
        text-align: center;
        text-wrap: nowrap;
        display: block;
        color: white;
        font-size: 11px;
      }
    }
  }

  @keyframes bb-plus-tag-anim {
    // Show tag
    0% {
      width: 0;
      opacity: 0;
    }
    10% {
      width: 76px;
      opacity: 1;
    }

    // keep tag

    // hide tag
    90% {
      opacity: 1;
      width: 76px;
    }
    100% {
      width: 4px;
      opacity: 0;
    }
  }
</style>
